import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  document.title="Team | Pluto Socio"
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="Our Philosophy"
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.pexels.com/photos/2239914/pexels-photo-2239914.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        description="At our company, we believe that success comes from collaboration, hard work, and a dedication to our clients. We are committed to delivering high-quality work that meets and exceeds our clients' expectations, while also fostering a positive, supportive work environment for our team. Our philosophy is rooted in the belief that by treating our clients and employees with respect, integrity, and honesty, we can build long-lasting relationships that benefit everyone involved. Thank you for considering our team for your next project."
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Our Expertise"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.pexels.com/photos/6963622/pexels-photo-6963622.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        textOnLeft={false}
        description="Our team is made up of skilled professionals who have a wealth of knowledge and experience in their respective fields. From web design to marketing to customer support, we have the expertise needed to help our clients achieve their goals. We stay up-to-date on the latest industry trends and technologies, and we are always looking for ways to improve and innovate. When you work with us, you can trust that you are working with a team of experts who are committed to delivering the best possible results for your business."
      />
       {/* <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.pexels.com/photos/15569152/pexels-photo-15569152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.pexels.com/photos/14664626/pexels-photo-14664626.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        textOnLeft={false}
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.pexels.com/photos/14699589/pexels-photo-14699589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
       <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      /> */}
     
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
